import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { useUserContext } from '~context/UserContext/UserSessionContext';
import Container from '~components/Container';
import Link from '~components/Link';

import Picture from './images/backstage.png';
import BackstagePassHeader from '../BackstagePassHeader';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#54304b',
    width: '100%',
    maxWidth: 'initial'
  },
  leftColumn: {
    padding: '2rem 1rem',
    textAlign: 'center',
    fontSize: '60px',
    [theme.breakpoints.only('lg')]: {
      fontSize: '55px'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '50px'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '45px'
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '40px',
      order: 2
    }
  },
  heroImage: {
    display: 'flex',
    maxWidth: '100%',
    height: 'auto'
  },
  behindTheScenes: {
    fontFamily: 'Roboto Slab',
    fontSize: '0.7833333333333333em',
    color: 'white',
    fontWeight: 700,
    textAlign: 'center'
  },
  tishaBavAfternoon: {
    fontFamily: 'Roboto Slab',
    fontSize: '1em',
    color: '#fcd568',
    fontWeight: 300,
    textAlign: 'center'
  },
  dateUnderImage: {
    fontFamily: 'Roboto Slab',
    fontSize: '1em',
    lineHeight: '1.05em',
    color: 'white',
    fontWeight: 700,
    textAlign: 'center'
  },
  rightColumn: {
    padding: '2rem 1rem',
    textAlign: 'center',
    fontSize: '78px',
    [theme.breakpoints.only('lg')]: {
      fontSize: '70px'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '65px'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '60px'
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '55px',
      order: 1
    }
  },
  with: {
    fontFamily: 'Roboto Slab',
    fontSize: '0.4795918367346939em',
    color: '#fcd568',
    fontWeight: 300
  },
  rabbiFohrman: {
    fontFamily: 'Roboto Slab',
    fontSize: '1em',
    lineHeight: '1.05em',
    color: 'white',
    fontWeight: 700
  },
  heroSubtitle: {
    fontSize: '0.2857142857142857em',
    fontWeight: 400,
    color: '#d6a7cd',
    margin: '2em 0 0.4em',
    textAlign: 'left'
  },
  heroSubtitleText: {
    display: 'inline',
    fontSize: '1em',
    fontWeight: 400,
    color: '#d6a7cd'
  },
  buttonTextEnd: {
    fontFamily: 'Roboto Slab',
    fontWeight: 900,
    fontSize: '0.3857142857142857em',
    textTransform: 'none',
    color: '#54304b',
    backgroundColor: '#fcd568',
    padding: '0.5em 2em',
    borderRadius: '8px',
    boxShadow: 'unset',
    marginBottom: '1em'
  },
  underlineText: {
    fontStyle: 'italic',
    fontSize: '0.3040816326530612em',
    textAlign: 'left',
    color: '#d6a7cd',
    fontWeight: 100
  },
  underlineTextLink: {
    fontStyle: 'normal',
    textDecoration: 'none',
    fontSize: '1em',
    textAlign: 'left',
    color: '#fcd568',
    fontWeight: 700,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  liveSpan: {
    background: '#FF5D46',
    borderRadius: '4px',
    padding: '0 0.0408163265306122em',
    color: 'white'
  }
}));

const KinnotBackstageSection = () => {
  const classes = useStyles();
  const { session } = useUserContext();
  const { isProducer } = session;

  return (
    <Container id="kinnot-backstage-pass-details" center className={classes.container}>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid
          container
          item
          xs={12}
          md={6}
          direction="column"
          justify="center"
          className={classes.leftColumn}
        >
          <img src={Picture} alt="Kinnot" className={classes.heroImage} />
          <Typography variant="body1" className={classes.behindTheScenes}>
            BEHIND THE SCENES
          </Typography>
          <Typography variant="body1" className={classes.tishaBavAfternoon}>
            TISHA B’AV AFTERNOON
          </Typography>
          <Typography variant="body1" className={classes.dateUnderImage}>
            JULY 18, 3PM&nbsp;-&nbsp;4PM&nbsp;ET
          </Typography>
        </Grid>
        <Grid item xs={12} md={5} className={classes.rightColumn}>
          <Typography variant="h1">
            <BackstagePassHeader fontSizePx={78} />
          </Typography>

          <Typography variant="body1" className={classes.with}>
            with
          </Typography>

          <Typography variant="body1" className={classes.rabbiFohrman}>
            Rabbi Fohrman
          </Typography>

          <Typography variant="body1" className={classes.heroSubtitle}>
            Join Rabbi Fohrman and Immanuel Shalev for an in-depth look at this year&#39;s Tisha
            B&#39;Av video. Engage in meaningful Tisha B&#39;Av conversation and discover the vast
            amount of material that didn&#39;t make the final cut.{' '}
            {isProducer ? (
              <Typography variant="caption" className={classes.heroSubtitleText}>
                Thank you for partnering with us and allowing Aleph Beta&#39;s unique and meaningful
                content to spread worldwide.
              </Typography>
            ) : (
              <Typography className={classes.heroSubtitleText}>
                This exclusive <span className={classes.liveSpan}>LIVE</span> Patrons Circle Event
                will give you a chance to go behind-the-scenes and interact with the visionaries of
                Aleph Beta while enhancing your connection to Tisha B&#39;Av. Partner with us and
                join Aleph Beta&#39;s inner circle.
              </Typography>
            )}
          </Typography>

          {isProducer ? (
            <Button
              component={Link}
              variant="contained"
              color="primary"
              size="large"
              className={classes.buttonTextEnd}
              underline="none"
              target="_blank"
              to="https://us02web.zoom.us/meeting/register/tZUld-qgrzwoE9X2Sz-1NxiP4NJbFdbx6QJz"
            >
              Register Now
            </Button>
          ) : (
            <>
              <Button
                component={Link}
                variant="contained"
                color="primary"
                size="large"
                className={classes.buttonTextEnd}
                underline="none"
                target="_blank"
                to="/patrons-circle"
              >
                Join The Circle
              </Button>
              <Typography className={classes.underlineText}>
                This event is an exclusive offer for our Patrons Circle members.
                <br />
                Support the Torah you love by becoming a Producer and partnering with us.
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default KinnotBackstageSection;

import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { useUserContext } from '~context/UserContext/UserSessionContext';
import Container from '~components/Container';
import Link from '~components/Link';
import { lineFix } from '~modules/kinnot-landing-page/kinnot-landing-page.utils';

import Picture from './images/kinot-live.png';
import KinnotLiveHeader from '../KinnotLiveHeader';

const useStyles = makeStyles(theme => ({
  overlineText: {
    fontSize: '0.4795918367346939em',
    fontFamily: 'Roboto Slab',
    fontWeight: 300,
    color: '#8bacc4',
    textTransform: 'uppercase'
  },
  heroOverHeading: {
    fontSize: '0.4795918367346939em',
    fontFamily: 'Roboto Slab',
    fontWeight: 300,
    color: '#8bacc4',
    textTransform: 'normal'
  },
  heroHeading: {
    fontFamily: 'Roboto Slab',
    fontSize: '1em',
    lineHeight: '1.05em',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: 'white'
  },
  heroSubtitle: {
    fontSize: '0.2857142857142857em',
    fontWeight: 400,
    color: '#8bacc4',
    margin: '2em 0 40px',
    textAlign: 'left'
  },
  buttonTextEnd: {
    fontFamily: 'Roboto Slab',
    fontWeight: 900,
    fontSize: '0.358974358974359em',
    textTransform: 'none',
    color: '#1b233f',
    backgroundColor: '#8bacc4',
    padding: '0.5em 2em',
    borderRadius: '8px',
    boxShadow: 'unset',
    marginBottom: '1em'
  },
  leftColumn: {
    textAlign: 'center',
    fontSize: '78px',
    [theme.breakpoints.only('lg')]: {
      fontSize: '70px'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '65px'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '60px'
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '55px'
    }
  },
  rightColumn: {
    textAlign: 'center',
    fontSize: '60px',
    [theme.breakpoints.only('lg')]: {
      fontSize: '55px'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '50px'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '45px'
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '40px'
    }
  },

  underlineText: {
    fontStyle: 'italic',
    fontSize: '0.33em',
    textAlign: 'left',
    color: '#8bacc4',
    fontWeight: 100
  },
  underlineTextLink: {
    fontStyle: 'normal',
    textDecoration: 'none',
    textAlign: 'left',
    color: '#8bacc4',
    fontWeight: 700,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  heroImage: {
    display: 'flex',
    maxWidth: '100%',
    height: 'auto'
  },
  container: {
    padding: '2rem 1rem',
    backgroundColor: '#1b233f',
    width: '100%',
    maxWidth: 'initial'
  },
  liveSpan: {
    background: '#FF5D46',
    borderRadius: '4px',
    padding: '0px 7px',
    color: 'white'
  },
  titleUnderImage: {
    fontFamily: 'Roboto Slab',
    fontSize: '1em',
    color: '#8bacc4',
    fontWeight: 300,
    textAlign: 'center'
  },
  dateUnderImage: {
    fontFamily: 'Roboto Slab',
    fontSize: '1em',
    lineHeight: '1.05em',
    color: 'white',
    fontWeight: 700,
    textAlign: 'center'
  },
  notificationUnderImage: {
    fontStyle: 'italic',
    fontSize: '0.4em',
    textAlign: 'left',
    color: '#8bacc4',
    fontWeight: 100,
    margin: '1em'
  }
}));

const KinnotLiveSection = () => {
  const classes = useStyles();
  const { session } = useUserContext();
  const { isPrivilegedMember } = session;

  return (
    <Container id="kinnot-live-event-details" center className={classes.container}>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12} md={5} className={classes.leftColumn}>
          <Typography variant="body1" className={classes.overlineText}>
            kinot registration
          </Typography>

          <KinnotLiveHeader fontSizePx={98} />

          <Typography variant="body1" className={classes.heroOverHeading}>
            with
          </Typography>

          <Typography variant="body1" className={classes.heroHeading}>
            Rabbi Fohrman
          </Typography>

          <Typography variant="subtitle1" className={classes.heroSubtitle}>
            Come join us for a meaningful, in-depth discussion of Kinot.
            <br />
            This exclusive <span className={classes.liveSpan}>LIVE</span>{' '}
            {lineFix(`Premium Webinar will transform your Tisha
            B&lsquo;Av!`)}
          </Typography>

          {isPrivilegedMember ? (
            <Button
              component={Link}
              variant="contained"
              color="primary"
              size="large"
              className={classes.buttonTextEnd}
              underline="none"
              target="_blank"
              to="https://us02web.zoom.us/meeting/register/tZMkdOCoqjMiGNyamB_mn5NZF-9M1MVUMheQ"
            >
              Register Now
            </Button>
          ) : (
            <>
              <Button
                component={Link}
                variant="contained"
                color="primary"
                size="large"
                className={classes.buttonTextEnd}
                underline="none"
                target="_blank"
                to="/subscribe"
              >
                Join Premium To Access
              </Button>
              <Typography className={classes.underlineText}>
                This event is an exclusive offer for our Premium members.
              </Typography>
              <Typography className={classes.underlineText}>
                Upgrade to Premium today to access.
              </Typography>
            </>
          )}
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={6}
          direction="column"
          justify="center"
          className={classes.rightColumn}
        >
          <img src={Picture} alt="Kinnot" className={classes.heroImage} />
          <Typography variant="body1" className={classes.titleUnderImage}>
            TISHA B’AV MORNING
          </Typography>
          <Typography variant="body1" className={classes.dateUnderImage}>
            JULY 18, 10AM&nbsp;-&nbsp;1PM&nbsp;ET
          </Typography>
          <Typography variant="body1" className={classes.notificationUnderImage}>
            Not sure who will watch the kids? All attendees will receive access to an exclusive,
            LIVE, Tisha B’Av themed drawing class for children, with AND-I-DREW, 12 PM ET - 1 PM ET.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default KinnotLiveSection;

import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Scroll from 'react-scroll';

import LivePicture from './images/kinot-live.png';
import BackstagePicture from './images/backstage.png';
import KinnotLiveHeader from '../KinnotLiveHeader';
import BackstagePassHeader from '../BackstagePassHeader';

const useStyles = makeStyles(theme => ({
  containerSpacing: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '100px',
      paddingBottom: '100px'
    }
  },
  liveWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#1b233f',
    padding: '1em 1em',
    fontSize: '62px',
    [theme.breakpoints.only('lg')]: {
      fontSize: '55px'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '47px'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '39px'
    },
    [theme.breakpoints.only('xs')]: {
      paddingTop: '1.5em',
      fontSize: '31px'
    }
  },
  backstageWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#54304b',
    padding: '1em 1em',
    textAlign: 'center',
    fontSize: '62px',
    [theme.breakpoints.only('lg')]: {
      fontSize: '55px'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '47px'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '39px'
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '31px'
    }
  },
  liveHeader: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.7580645161290323em',
    fontFamily: 'Roboto Slab',
    fontWeight: 300,
    fontStyle: 'normal',
    textTransform: 'uppercase',
    color: '#8bacc4'
  },
  backstageHeader: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.7580645161290323em',
    fontFamily: 'Roboto Slab',
    fontWeight: 300,
    fontStyle: 'normal',
    textTransform: 'uppercase',
    color: '#fcd568'
  },
  dateText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.7580645161290323em',
    fontFamily: 'Roboto Slab',
    fontWeight: 500,
    fontStyle: 'normal',
    textTransform: 'uppercase',
    color: '#fff'
  },
  buttonLive: {
    fontFamily: 'Roboto Slab',
    fontWeight: '700',
    fontSize: '0.4516129032258065em',
    textTransform: 'none',
    color: '#1b233f',
    padding: '0.4em 1.4em',
    boxShadow: 'unset',
    marginTop: '1em'
  },
  buttonBackstage: {
    fontFamily: 'Roboto Slab',
    fontWeight: '700',
    fontSize: '0.4516129032258065em',
    textTransform: 'none',
    color: '#54304b',
    backgroundColor: '#fcd568',
    padding: '0.4em 1.4em',
    boxShadow: 'unset',
    marginTop: '1em'
  },
  image: {
    display: 'flex',
    maxWidth: '719px', // width of the image with the smallest width.
    width: '100%',
    height: 'auto'
  },
  topHeaderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    marginBottom: '-3rem',
    paddingBottom: '1.2rem',
    fontSize: '28px',
    [theme.breakpoints.only('lg')]: {
      fontSize: '26px'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '20px'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '18px'
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '15px'
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      borderRadius: '50%',
      boxShadow: '0 0 0 500px #458fce',
      bottom: '-5.3rem',
      right: '-50px',
      left: '-50px',
      height: '8rem'
    }
  },
  topHeader: {
    position: 'relative',
    zIndex: 2,
    color: 'white',
    fontFamily: 'Roboto Slab',
    fontWeight: '300',
    fontSize: '1em',
    padding: '0'
  },
  topHeaderBold: {
    fontWeight: 500
  },
  specialEventsPill: {
    position: 'relative',
    zIndex: 2,
    padding: '0.5em 1em',
    fontFamily: 'Roboto Slab',
    fontWeight: 700,
    fontSize: '1em',
    textTransform: 'uppercase',
    backgroundColor: 'white',
    color: '#456fce',
    borderRadius: '1.214em',
    margin: '0'
  },
  heightFixingWrapper: {
    height: '2.1em',
    [theme.breakpoints.down('lg')]: {
      height: '3em'
    }
  }
}));

const HeaderSection = React.memo(() => {
  const classes = useStyles();

  return (
    <div className={classes.topHeaderWrapper}>
      <h1 className={classes.topHeader}>
        Make <strong className={classes.topHeaderBold}>Tisha B&lsquo;Av</strong> Meaningful with{' '}
        <strong className={classes.topHeaderBold}>Aleph Beta</strong>
      </h1>

      <p className={classes.specialEventsPill}>2 Special Events</p>
    </div>
  );
});

const KinnotHero = () => {
  const classes = useStyles();

  return (
    <>
      <HeaderSection />

      <Grid container direction="row" justify="space-between">
        <Grid item xs={12} md={6} className={classes.liveWrapper}>
          <div className={classes.heightFixingWrapper}>
            <KinnotLiveHeader fontSizePx={62} />
          </div>

          <img alt="" src={LivePicture} className={classes.image} />

          <Typography variant="body1" className={classes.liveHeader}>
            TISHA B’AV MORNING
          </Typography>
          <Typography variant="body1" className={classes.dateText}>
            JULY 18, 10AM - 1PM ET
          </Typography>

          <Button
            component={Scroll.Link}
            variant="contained"
            color="primary"
            size="large"
            className={classes.buttonLive}
            underline="none"
            to="kinnot-live-event-details"
          >
            Learn More
          </Button>
        </Grid>
        <Grid item xs={12} md={6} className={classes.backstageWrapper}>
          <div className={classes.heightFixingWrapper}>
            <BackstagePassHeader fontSizePx={62} />
          </div>
          <img alt="" src={BackstagePicture} className={classes.image} />

          <Typography variant="body1" className={classes.backstageHeader}>
            TISHA B’AV AFTERNOON
          </Typography>
          <Typography variant="body1" className={classes.dateText}>
            JULY 18, 3 PM - 4 PM ET
          </Typography>

          <Button
            component={Scroll.Link}
            variant="contained"
            color="primary"
            size="large"
            className={classes.buttonBackstage}
            underline="none"
            to="kinnot-backstage-pass-details"
          >
            Learn More
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default KinnotHero;

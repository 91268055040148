import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { useUserContext } from '~context/UserContext/UserSessionContext';

import Container from '~components/Container';
import Link from '~components/Link';

import {
  lineFix,
  calculatePremiumUsersButtonLink,
  calculatePremiumUsersButtonTitle
} from '~modules/kinnot-landing-page/kinnot-landing-page.utils';

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: '56px',
    lineHeight: '66px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '46px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '40px',
      lineHeight: '55px'
    }
  },
  subtitle: {
    fontSize: '20px',
    lineHeight: '30px',
    textAlign: 'center',
    marginBottom: '46px'
  },

  buttonText: {
    fontWeight: '500',
    fontSize: '24px',
    color: '#FFF',
    lineHeight: '30px',
    textTransform: 'none',
    padding: '15px 42px',
    boxShadow: 'unset',
    marginBottom: '16px'
  },
  containerSpacing: {
    paddingTop: '126px',
    paddingBottom: '126px'
  }
}));

const KinnotCta = ({ isEventLive, isEventEnded }) => {
  const classes = useStyles();
  const { session } = useUserContext();
  const { isPrivilegedMember } = session;

  return (
    <Container center background="#FFDBA3" className={classes.containerSpacing} maxWidth="md">
      <Typography variant="h1" className={classes.heading}>
        {lineFix(`Don&lsquo;t Miss Out on This Inspiring Tisha B&lsquo;Av Experience!`)}
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>
        {lineFix(` Join us live for Kinot with Rabbi Fohrman, Beth, and Ami, and discover the deeper meanings
        of these beautiful prayers.`)}
      </Typography>
      {!isPrivilegedMember && !isEventEnded && (
        <Button
          component={Link}
          variant="contained"
          color="primary"
          size="large"
          className={classes.buttonText}
          underline="none"
          target="_blank"
          to="/subscribe"
        >
          Join Premium for Access
        </Button>
      )}{' '}
      {isPrivilegedMember && !isEventEnded && (
        <Button
          component={Link}
          variant="contained"
          color="primary"
          size="large"
          className={classes.buttonText}
          underline="none"
          target="_blank"
          to={calculatePremiumUsersButtonLink(isEventLive, isEventEnded)}
        >
          {calculatePremiumUsersButtonTitle(isEventLive, isEventEnded)}
        </Button>
      )}
      {isEventEnded && (
        <Button
          component={Link}
          variant="contained"
          color="primary"
          size="large"
          className={classes.buttonText}
          underline="none"
          target="_blank"
          to={calculatePremiumUsersButtonLink(isEventLive, isEventEnded)}
        >
          {calculatePremiumUsersButtonTitle(isEventLive, isEventEnded)}
        </Button>
      )}
    </Container>
  );
};

KinnotCta.propTypes = {
  isEventLive: PropTypes.bool.isRequired,
  isEventEnded: PropTypes.bool.isRequired
};

export default KinnotCta;

import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';

import Picture from './images/learn-to-draw.png';
import SignaturePicture from './images/andidrew-signature.png';

const useStyles = makeStyles(theme => ({
  rightColumn: {
    textAlign: 'center',
    fontSize: '98px',
    [theme.breakpoints.only('lg')]: {
      fontSize: '72px'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '50px'
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '44px'
    }
  },
  with: {
    fontFamily: 'Roboto Slab',
    fontWeight: 300,
    fontSize: '0.4795918367346939em',
    lineHeight: '1.05em'
  },
  andrewGalitzer: {
    fontSize: '1em',
    lineHeight: '1.05em',
    fontFamily: 'Roboto Slab',
    fontWeight: 700
  },
  creatorOf: {
    fontFamily: 'Roboto Slab',
    fontSize: '0.5816326530612245em',
    lineHeight: '1.05em',
    fontWeight: 700
  },
  exclusiveTisha: {
    fontFamily: 'Roboto Slab',
    fontWeight: 300,
    fontSize: '0.4795918367346939em',
    lineHeight: '1.05em',
    margin: '1em 0'
  },
  july18: {
    fontFamily: 'Roboto Slab',
    fontSize: '0.6122448979591837em',
    lineHeight: '1.05em',
    fontWeight: 700
  },
  registerFor: {
    fontFamily: 'Roboto Slab',
    fontWeight: 300,
    fontSize: '0.4081632653061224em'
  },
  andidrewSignature: {
    display: 'flex',
    maxWidth: '80%'
  },
  heroImage: {
    display: 'flex',
    maxWidth: '100%',
    height: 'auto'
  },
  containerSpacing: {
    padding: '2rem',
    backgroundColor: 'white',
    width: '100%',
    maxWidth: 'initial'
  }
}));

const KinnotDrawingClassesSection = () => {
  const classes = useStyles();

  return (
    <Container center className={classes.containerSpacing}>
      <Grid container direction="row" justify="center" alignItems="flex-start">
        <Grid item xs={12} md={5}>
          <img
            src={Picture}
            alt="Learn to Draw"
            title="Learn to Draw"
            className={classes.heroImage}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.rightColumn}>
          <Typography variant="body1" className={classes.with}>
            with
          </Typography>
          <Typography variant="body1" className={classes.andrewGalitzer}>
            Andrew Galitzer
          </Typography>
          <Typography variant="body1" className={classes.creatorOf}>
            creator of Parsha Comics
          </Typography>
          <Typography variant="body1" className={classes.exclusiveTisha}>
            Exclusive Tisha B’Av Class
            <br />
            for Children of Webinar Attendees
          </Typography>

          <Typography variant="body1" className={classes.july18}>
            JULY 18, 12PM - 1PM ET
          </Typography>
          <Typography variant="body1" className={classes.registerFor}>
            Register for Kinot Event to Gain Access
          </Typography>
          <img
            alt="ANDiDREW.com"
            src={SignaturePicture}
            title="I am not a button, just a picture of an URL"
            className={classes.andidrewSignature}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default KinnotDrawingClassesSection;

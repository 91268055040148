import htmlParser from 'html-react-parser';

export const lineFix = text => {
  const t = text.replace(/\s+(\S+\s*$)/, '&nbsp;$1');
  return htmlParser(t);
};

export const calculatePremiumUsersButtonLink = (isEventLive, isEventEnded) => {
  if (isEventLive) {
    return 'https://us02web.zoom.us/w/89728997377?tk=-9l4xPEe9WVrU3owTpYyot6QtHjPejPteZ41fH6gGOo.DQIAAAAU5EPYARZRZVRQSkZkU1NjeVNmaWpzdTJ1Y3J3AAAAAAAAAAAAAAAAAAAAAAAAAAAA';
  }
  if (isEventEnded) {
    return '/playlist/kinotevent2020';
  }
  return 'https://us02web.zoom.us/meeting/register/tZ0qdeGhpzwpHdN3-FQGy2ql-cLPBofGdAQC';
};

export const calculatePremiumUsersButtonTitle = (isEventLive, isEventEnded) => {
  if (isEventLive) {
    return 'Join Live Kinot Event';
  }
  if (isEventEnded) {
    return 'Watch Now';
  }
  return 'Register Now';
};

import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import RabbiFohrmanAvatar from './images/rabbi-david-fohrman.png';
import AmiSilverAvatar from './images/ami-silver.png';
import BethLeschAvatar from './images/beth-lesch.png';
import DanielAvatar from './images/daniel.png';

const useStyles = makeStyles(theme => ({
  hostName: {
    fontSize: '1em',
    fontFamily: 'Roboto Slab',
    fontWeight: 900,
    color: '#609fd6',
    marginBottom: '0.4em',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  hostStatus: {
    fontSize: '0.7446808510638298em',
    fontFamily: 'Roboto Slab',
    fontWeight: 900,
    color: '#5b5b5b',
    marginBottom: '0.4em',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  hostInfo: {
    fontSize: '0.6382978723404255em',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#474747',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  secondaryHostInfo: {
    fontSize: '0.3833333333333333em',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#609fd6',
    textAlign: 'center'
  },
  secondaryHostName: {
    fontSize: '0.5em',
    fontFamily: 'Roboto Slab',
    fontWeight: 900,
    margin: '1em 0 0.5em',
    textAlign: 'center'
  },
  media: {
    maxWidth: '100%',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      paddingBottom: '20px',
      paddingTop: '20px'
    }
  },
  subHeading: {
    margin: '0 auto',
    paddingBottom: '65px',
    paddingTop: '35px',
    textAlign: 'center',
    fontFamily: 'Roboto Slab',
    fontSize: '1em',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '35px'
    }
  },
  hostTextWrapper: {
    paddingLeft: '3em',
    paddingRight: '1em',
    [theme.breakpoints.only('xs')]: {
      paddingLeft: '1em'
    }
  },
  hostWrapper: {
    padding: '1em 0',
    backgroundColor: '#f6f6f6',
    fontSize: '37px',
    [theme.breakpoints.only('lg')]: {
      fontSize: '35px'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '32px'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '29px'
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '26px'
    }
  },
  secondaryHostsWrapper: {
    padding: '1em 0',
    backgroundColor: 'white',
    fontSize: '51px',
    [theme.breakpoints.only('lg')]: {
      fontSize: '49px'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '45px'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '41px'
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '38px'
    }
  },
  secondaryHostsInfoWrapper: {
    maxWidth: '352px',
    margin: '0 2.5rem'
  }
}));

const KinnotHosts = () => {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.hostWrapper}
      >
        <Grid item xs={12} md={2}>
          <img src={RabbiFohrmanAvatar} alt="Rabbi David Fohrman" className={classes.media} />
        </Grid>
        <Grid item xs={12} md={8} className={classes.hostTextWrapper}>
          <Typography variant="h3" className={classes.hostName}>
            Rabbi David Fohrman
          </Typography>
          <Typography variant="body1" className={classes.hostStatus}>
            Founder and Lead Scholar
          </Typography>
          <Typography variant="body1" className={classes.hostInfo}>
            is an internationally renowned lecturer on biblical themes, and the founder and
            principal educator at Aleph Beta. He has served as an adjunct professor at Johns Hopkins
            University, and as a lead writer and editor for ArtScroll&#39;s Talmud translation
            project. Rabbi Fohrman has also served as scholar for the Hoffberger Foundation for
            Torah Study. He is the author of The Beast That Crouches at the Door, a finalist for the
            2007 National Jewish Book Award, The Queen You Thought You Knew, and The Exodus You
            Almost Passed Over. His most recent publication is Exodus: A Parsha Companion, the
            second of a five volume set on the weekly Torah portion.
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction="column" justify="center" className={classes.secondaryHostsWrapper}>
        <Grid item>
          <Typography variant="h4" className={classes.subHeading}>
            Joined By:
          </Typography>
        </Grid>
        <Grid container item direction="row" justify="center" alignItems="flex-start">
          <Grid
            container
            item
            sm={12}
            md={4}
            direction="column"
            justify="flex-start"
            alignItems="center"
            className={classes.secondaryHostsInfoWrapper}
          >
            <img src={BethLeschAvatar} alt="Beth avatar" className={classes.media} />
            <Typography variant="h3" className={classes.secondaryHostName}>
              Beth
            </Typography>
            <Typography variant="body1" className={classes.secondaryHostInfo}>
              Beth has been a Scholar at Aleph Beta since 2016 (her dream job!). Before coming to
              Aleph Beta, she studied at Midreshet Rachel v’Chaya and Nishmat and directed Ma’ayan:
              Torah Study from the Sources. She recently moved to Jerusalem with her husband and
              children to begin a new adventure.
            </Typography>
          </Grid>
          <Grid
            container
            item
            sm={12}
            md={4}
            direction="column"
            justify="flex-start"
            alignItems="center"
            className={classes.secondaryHostsInfoWrapper}
          >
            <img src={DanielAvatar} alt="Daniel avatar" className={classes.media} />
            <Typography variant="h3" className={classes.secondaryHostName}>
              Daniel
            </Typography>
            <Typography variant="body1" className={classes.secondaryHostInfo}>
              Daniel Loewenstein is a researcher and content creator at Aleph Beta. He also recently
              earned a PhD in Education and Jewish Studies from NYU Steinhardt, and is an alumnus of
              the Azrieli School of Jewish Education and Skokie Yeshiva. He lives in Woodmere, NY.
            </Typography>
          </Grid>
          <Grid
            container
            item
            sm={12}
            md={4}
            direction="column"
            justify="flex-start"
            alignItems="center"
            className={classes.secondaryHostsInfoWrapper}
          >
            <img src={AmiSilverAvatar} alt="Ami avatar" className={classes.media} />
            <Typography variant="h3" className={classes.secondaryHostName}>
              Ami
            </Typography>
            <Typography variant="body1" className={classes.secondaryHostInfo}>
              Ami is a writer, research scholar and content creator at Aleph Beta. He is a Rabbi and
              Psychotherapist living, working and writing from Jerusalem
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default KinnotHosts;

import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '~components/Container';
import Divider from '~components/Divider';

import { lineFix } from '~modules/kinnot-landing-page/kinnot-landing-page.utils';
import { OwnWebsitePromoLink } from '~components/Link';
import RabbiFohrmanAvatar from './images/rabbi-david-fohrman.png';
import AmiSilverAvatar from './images/ami-silver.png';
import BethLeschAvatar from './images/beth-lesch.png';

const useStyles = makeStyles(theme => ({
  hostName: {
    fontSize: '26px',
    lineHeight: '30px',
    fontWeight: '500',
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  hostInfo: {
    fontSize: '18px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#000000',
    opacity: '0.62',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  secondaryHostInfo: {
    fontSize: '16px',
    lineHeight: '22px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#000000',
    opacity: '0.62',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  secondaryHosName: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: '500',
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  containerPosition: {
    marginTop: '140px',
    marginBottom: '170px'
  },
  media: {
    maxWidth: '100%',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      paddingBottom: '20px',
      paddingTop: '20px'
    }
  },
  subHeading: {
    margin: '0 auto',
    paddingBottom: '65px',
    paddingTop: '35px',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '35px'
    }
  },
  divider: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    width: '1px',
    height: '128px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

const KinnotHosts = () => {
  const classes = useStyles();

  return (
    <Container className={classes.containerPosition}>
      <Grid container direction="row" justify="center" alignItems="flex-start">
        <Grid item xs={12} md={2}>
          <img src={RabbiFohrmanAvatar} alt="Rabbi David Fohrman" className={classes.media} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="h3" className={classes.hostName}>
            Rabbi David Fohrman
          </Typography>
          <Typography variant="subtitle1" className={classes.hostInfo}>
            Is the founder and CEO of Aleph Beta, a non-profit media company that produces
            high-quality Torah videos highlighting a unique methodology in studying Torah text in a
            way that is both intellectually rigorous and emotionally engaging. Rabbi Fohrman also
            lectures internationally on Biblical themes. He served as a senior writer and editor for
            ArtScroll’s Schottenstein Edition of the Talmud, and taught Biblical themes at Johns
            Hopkins University. Rabbi Fohrman’s recorded lectures and animated videos are available
            at <OwnWebsitePromoLink />. His published works include The Beast That Crouches at the
            Door, The Queen You Thought You Knew, and The Exodus You Almost Passed Over.
          </Typography>
        </Grid>
      </Grid>
      <Divider spacing={8} />

      <Typography variant="h4" className={classes.subHeading}>
        Joined By:
      </Typography>

      <Grid container direction="row" justify="center" alignItems="center">
        <Grid
          container
          item
          xs={12}
          md={5}
          direction="row"
          justify="space-around"
          alignItems="center"
        >
          <Grid item xs={12} md={3}>
            <img src={BethLeschAvatar} alt="Rabbi David Fohrman" className={classes.media} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h3" className={classes.secondaryHosName}>
              Beth Lesch
            </Typography>
            <Typography variant="subtitle1" className={classes.secondaryHostInfo}>
              {lineFix(` Beth has been a Scholar at Aleph Beta since 2016 (her dream job!). She has written
              parsha and holiday guides, been a host on the Parsha Lab podcast, and authored many
              parsha videos.`)}
            </Typography>
          </Grid>
        </Grid>
        <Divider orientation="vertical" component="div" className={classes.divider} />
        <Grid
          container
          item
          xs={12}
          md={5}
          direction="row"
          justify="space-around"
          alignItems="center"
        >
          <Grid item xs={12} md={3}>
            <img src={AmiSilverAvatar} alt="Rabbi David Fohrman" className={classes.media} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h3" className={classes.secondaryHosName}>
              Ami Silver
            </Typography>
            <Typography variant="subtitle1" className={classes.secondaryHostInfo}>
              {lineFix(`Ami is a writer, research scholar and content creator at Aleph Beta. He is a Rabbi and
              Psychotherapist living, working and writing from Jerusalem.`)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default KinnotHosts;

import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  header: props => ({
    fontFamily: 'Roboto Slab',
    fontWeight: 500,
    fontStyle: 'normal',
    letterSpacing: 'normal',
    fontSize: `${props.fontSizePx}px`,
    textTransform: 'uppercase',
    lineHeight: '1.05em',
    color: '#FFF',
    [theme.breakpoints.only('sm')]: {
      fontSize: `${props.fontSizePx * 0.7}px`
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: `${props.fontSizePx * 0.5}px`
    }
  }),
  highlightedWord: {
    display: 'inline-block',
    borderRadius: '3px',
    paddingInlineStart: '0.3em',
    paddingInlineEnd: '0.3em'
  }
}));

const BackstagePassHeader = React.memo(({ fontSizePx }) => {
  const classes = useStyles({ fontSizePx });
  return (
    <Typography className={classes.header}>
      BACKSTAGE{' '}
      <span
        className={classes.highlightedWord}
        style={{
          color: '#1b233f',
          backgroundColor: '#fcd568'
        }}
      >
        PASS
      </span>
    </Typography>
  );
});
BackstagePassHeader.propTypes = {
  fontSizePx: PropTypes.number
};
BackstagePassHeader.defaultProps = {
  fontSizePx: 73
};

export default BackstagePassHeader;

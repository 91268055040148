import React from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import EventIcon from '@material-ui/icons/Event';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import Container from '~components/Container';
import Link from '~components/Link';
import { renderer } from '~modules/kinnot-landing-page/components/shared';
import {
  lineFix,
  calculatePremiumUsersButtonLink,
  calculatePremiumUsersButtonTitle
} from '~modules/kinnot-landing-page/kinnot-landing-page.utils';
import KinnotHeroImage from './images/kinnot-hero.png';

const useStyles = makeStyles(theme => ({
  overlineText: {
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: '500',
    color: '#FFDBA3',
    opacity: '0.87',
    marginBottom: '13px'
  },
  heroHeading: {
    fontSize: '64px',
    lineHeight: '75px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#FFF',
    opacity: '0.87',
    marginBottom: '26px',
    [theme.breakpoints.only('sm')]: {
      fontSize: '50px',
      lineHeight: '65px'
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '44px',
      lineHeight: '58px'
    }
  },
  heroSubtitle: {
    fontSize: '24px',
    lineHeight: '38px',
    color: '#FFF',
    opacity: '0.87',
    marginBottom: '40px',
    fontWeight: '300'
  },
  dateText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '20px',
    lineHeight: '38px',
    color: '#FFF',
    opacity: '0.87',
    fontWeight: '300'
  },
  dateIcon: {
    fontSize: '27px',
    marginRight: '15px'
  },
  buttonText: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '30px',
    textTransform: 'none',
    color: '#fff',
    padding: '15px 42px',
    boxShadow: 'unset',
    marginTop: '50px',
    marginBottom: '16px'
  },
  underlineText: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#FFFFFF',
    opacity: '0.7'
  },
  heroImage: {
    display: 'flex',
    maxWidth: '100%',
    height: 'auto'
  },
  containerSpacing: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '100px',
      paddingBottom: '100px'
    }
  },
  liveSpan: {
    background: '#FF5D46',
    borderRadius: '4px',
    padding: '0px 7px',
    fontWeight: 500
  },
  buttonTextEnd: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '30px',
    textTransform: 'none',
    color: '#fff',
    padding: '15px 42px',
    boxShadow: 'unset',
    marginTop: '15px',
    marginBottom: '16px'
  },
  dateTextEnded: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    lineHeight: '36px',
    marginTop: '15px',
    color: '#FFF',
    opacity: '0.87',
    fontWeight: '300'
  }
}));

const KinnotHero = ({ isEventLive, isEventEnded }) => {
  const classes = useStyles();
  const { session } = useUserContext();
  const { isPrivilegedMember } = session;

  return (
    <Container size={100} center background="#2F3556;" className={classes.containerSpacing}>
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item xs={12} md={5}>
          <Typography variant="overline" className={classes.overlineText}>
            kinot registration
          </Typography>
          <Typography variant="h1" className={classes.heroHeading}>
            KINOT LIVE with Rabbi Fohrman
          </Typography>
          <Typography variant="subtitle1" className={classes.heroSubtitle}>
            Come join us for a meaningful, in-depth discussion of Kinot. This exclusive{' '}
            <span className={classes.liveSpan}>LIVE</span>{' '}
            {lineFix(`Premium Webinar will transform your Tisha
            B&lsquo;Av!`)}
          </Typography>
          <Typography variant="subtitle1" className={classes.dateText}>
            <EventIcon className={classes.dateIcon} /> Tisha B&lsquo;Av Morning - July 30, 10am ET -
            1pm ET
          </Typography>
          {!isPrivilegedMember && !isEventEnded && (
            <>
              <Button
                component={Link}
                variant="contained"
                color="primary"
                size="large"
                className={classes.buttonText}
                underline="none"
                to="/subscribe"
              >
                Join Premium for Access
              </Button>

              <Typography className={classes.underlineText}>
                This event is an exclusive offer for our Premium members.
              </Typography>
              <Typography className={classes.underlineText}>
                Upgrade to Premium today to reserve your spot.
              </Typography>
              <Typography className={classes.underlineText}>
                New to Aleph Beta? Discover all the amazing benefits of Premium membership{' '}
                <Link to="/subscribe" underline="always" className={classes.underlineText}>
                  here
                </Link>
                .
              </Typography>
            </>
          )}
          {isPrivilegedMember && !isEventEnded && (
            <>
              <Button
                component={Link}
                variant="contained"
                color="primary"
                size="large"
                className={classes.buttonText}
                underline="none"
                target="_blank"
                to={calculatePremiumUsersButtonLink(isEventLive, isEventEnded)}
              >
                {calculatePremiumUsersButtonTitle(isEventLive, isEventEnded)}
              </Button>

              <Typography className={classes.underlineText}>
                This event is an exclusive offer for our Premium members.
              </Typography>
              <Typography className={classes.underlineText}>
                Space is limited. Register today to reserve your spot.
              </Typography>
            </>
          )}

          {isEventEnded && (
            <>
              <Typography className={classes.dateTextEnded}>
                Unfortunately, this event has already ended. Click below to view the recording.
              </Typography>
              <Button
                component={Link}
                variant="contained"
                color="primary"
                size="large"
                className={classes.buttonTextEnd}
                underline="none"
                target="_blank"
                to={calculatePremiumUsersButtonLink(isEventLive, isEventEnded)}
              >
                {calculatePremiumUsersButtonTitle(isEventLive, isEventEnded)}
              </Button>
              {!isPrivilegedMember && (
                <>
                  <Typography className={classes.underlineText}>
                    This recording is an exclusive offer for our Premium members.
                  </Typography>
                  <Typography className={classes.underlineText}>
                    Upgrade to Premium today to access.
                  </Typography>
                  <Typography className={classes.underlineText}>
                    New to Aleph Beta? Discover all the amazing benefits of Premium membership{' '}
                    <Link to="/subscribe" underline="always" className={classes.underlineText}>
                      here
                    </Link>
                    .
                  </Typography>
                </>
              )}
            </>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <img src={KinnotHeroImage} alt="Kinnot" className={classes.heroImage} />
          {!isEventEnded && (
            <>
              <Countdown date="2020-07-30T10:00:00.000-04:00" renderer={renderer} />
              {!isEventLive && (
                <Typography
                  className={classes.overlineText}
                  style={{ textAlign: 'center', marginTop: '15px' }}
                >
                  Until the Kinot Event Starts
                </Typography>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

KinnotHero.propTypes = {
  isEventLive: PropTypes.bool.isRequired,
  isEventEnded: PropTypes.bool.isRequired
};

export default KinnotHero;

import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  header: props => ({
    fontFamily: 'Roboto Slab',
    fontWeight: 500,
    fontStyle: 'normal',
    letterSpacing: 'normal',
    fontSize: `${props.fontSizePx}px`,
    lineHeight: '1.05em',
    textTransform: 'uppercase',
    color: '#FFF',
    [theme.breakpoints.only('sm')]: {
      fontSize: `${props.fontSizePx * 0.7}px`
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: `${props.fontSizePx * 0.5}px`
    }
  }),
  highlightedWord: {
    display: 'inline-block',
    borderRadius: '3px',
    paddingInlineStart: '0.3em',
    paddingInlineEnd: '0.3em'
  }
}));

const KinnotLiveHeader = ({ fontSizePx }) => {
  const classes = useStyles({ fontSizePx });
  return (
    <Typography className={classes.header}>
      KINOT{' '}
      <span
        className={classes.highlightedWord}
        style={{ color: '#1b233f', backgroundColor: '#8bacc4' }}
      >
        LIVE
      </span>
    </Typography>
  );
};
KinnotLiveHeader.propTypes = {
  fontSizePx: PropTypes.number
};
KinnotLiveHeader.defaultProps = {
  fontSizePx: 73
};

export default KinnotLiveHeader;

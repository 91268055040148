import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Page from '~layout/Page';
import SEO from '~layout/SearchEngineOptimization';

import { KinnotHero, KinnotHosts, KinnotCta } from '~modules/kinnot-landing-page';

const seo = {
  seo_title: 'Aleph Beta | Learn Torah (Bible) & Jewish Studies Online',
  seo_description:
    'Bring the Torah to life with Aleph Beta’s Torah (Bible) & Jewish studies videos. Be excited by learning Torah, and find relevant meaning for modern-day life.',
  seo_keywords:
    'aleph beta, learn torah, jewish torah,kinnot,live kinnot event, webinar, torah online, torah videos, rabbi david fohrman, judaism religion, torah course, torah website'
};

const Kinnot = ({ location }) => {
  console.log(location);
  const [isEventLive, setIsEventLive] = useState(false);
  const [isEventEnded, setIsEventEnded] = useState(false);

  let dateNow = Date.now();
  const searchQuery = queryString.parse(location.search);
  console.log(searchQuery);
  const forceEnableEvent = !!searchQuery.forceEnableEvent;
  const eventLaunch = Date.parse('2020-07-30T10:00:00.000-04:00');
  const eventEnd = Date.parse('2020-07-30T13:30:00.000-04:00');

  useEffect(() => {
    setInterval(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dateNow = Date.now();

      if (forceEnableEvent || (dateNow > eventLaunch && dateNow < eventEnd)) {
        console.log('Enabled event');
        setIsEventLive(true);
      }

      if (!forceEnableEvent && dateNow > eventEnd) {
        console.log('Disabled event');
        setIsEventLive(false);
        setIsEventEnded(true);
      }
    }, 1000);
  }, [dateNow, eventLaunch, eventEnd]);

  return (
    <Page>
      <SEO {...seo} />
      <KinnotHero isEventLive={isEventLive} isEventEnded={isEventEnded} />
      <KinnotHosts />
      <KinnotCta isEventLive={isEventLive} isEventEnded={isEventEnded} />
    </Page>
  );
};
Kinnot.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};
export default Kinnot;

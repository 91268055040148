import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: '1em',
    fontFamily: 'Roboto Slab',
    fontWeight: 300,
    textAlign: 'center',
    color: 'white'
  },
  boldHeading: {
    fontSize: '1em',
    fontFamily: 'Roboto Slab',
    fontWeight: 700,
    textAlign: 'center',
    color: 'white'
  },
  container: {
    backgroundColor: '#458fce',
    padding: '2rem 0',
    fontSize: '61px',
    [theme.breakpoints.only('lg')]: {
      fontSize: '52px'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '43px'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '34px'
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '25px'
    }
  }
}));

const KinnotCta = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="body1" className={classes.heading}>
        Don&lsquo;t Miss Out on These Deeply Moving
      </Typography>
      <Typography variant="body1" className={classes.boldHeading}>
        Tisha B&lsquo;Av Experiences!
      </Typography>
    </div>
  );
};

export default KinnotCta;

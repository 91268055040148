import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
  timerStyle: {
    padding: '10px',
    background: 'rgba(0, 0, 0, 0.3)',
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '42px',
    textAlign: 'center',
    display: 'inline-block',
    color: '#fff',
    minWidth: '50px'
  },
  timerInfo: {
    fontWeight: '500',
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '0.1em',
    display: 'block',
    marginTop: '7px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    opacity: '0.5'
  },
  overlineText: {
    fontSize: '32px',
    lineHeight: '38px',
    fontWeight: '500',
    color: '#FFDBA3',
    opacity: '0.87',
    marginBottom: '13px',
    textAlign: 'center',
    display: 'block',
    marginTop: '20px'
  }
});

const Completionist = () => {
  const classes = useStyles();
  return <span className={classes.overlineText}>Happening Now. Don&lsquo;t Wait to Join. </span>;
};

const CountdownDiggits = ({ children }) => {
  const classes = useStyles();
  return <span className={classes.timerStyle}>{children}</span>;
};
CountdownDiggits.propTypes = {
  children: PropTypes.node.isRequired
};

const CountdownTimers = ({ children }) => {
  const classes = useStyles();
  return <span className={classes.timerInfo}>{children}</span>;
};
CountdownTimers.propTypes = {
  children: PropTypes.node.isRequired
};

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <Completionist />;
  }
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={2}
      style={{ marginTop: '21px' }}
    >
      <Grid item xs="auto">
        <CountdownDiggits>{days}</CountdownDiggits>
        <br />
        <CountdownTimers>Days</CountdownTimers>
      </Grid>
      <Grid item xs="auto">
        <CountdownDiggits>{hours}</CountdownDiggits>
        <br />

        <CountdownTimers>hours</CountdownTimers>
      </Grid>
      <Grid item xs="auto">
        <CountdownDiggits>{minutes}</CountdownDiggits>
        <br />

        <CountdownTimers>minutes</CountdownTimers>
      </Grid>
      <Grid item xs="auto">
        <CountdownDiggits>{seconds}</CountdownDiggits>
        <br />

        <CountdownTimers>seconds</CountdownTimers>
      </Grid>
    </Grid>
  );
};

renderer.propTypes = {
  days: PropTypes.number.isRequired,
  hours: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
  seconds: PropTypes.number.isRequired,
  completed: PropTypes.bool.isRequired
};

export default renderer;
